<template>
    <div>
        <TextBlock v-if="applicationSent" :cmsBlock="cmsBlockSuccess"/>
        <TextBlock v-if="applicationFailed" :cmsBlock="cmsBlockError"/>

        <TextBlock v-if="!applicationSent && !applicationFailed" :cmsBlock="cmsBlockIntro"/>

        <section v-if="!applicationSent && !applicationFailed" class="white no-padding">

            <div class="form">
                <h4 class="section-header">{{ $t('applicationGeneral') }}</h4>

                <div class="field-header">{{ $t('applicationApplicant') }}*</div>
                <div class="fieldset">
                    <InputField v-model="applicant.prefix"
                        :label="$t('applicationPrefix')"
                        ref="prefix"
                    />
                    <InputField v-model="applicant.title"
                        :label="$t('applicationTitle')"
                        ref="title"
                    />
                    <InputField v-model="applicant.firstname"
                        :label="$t('applicationFirstname')"
                        ref="firstname"
                    />
                    <InputField v-model="applicant.lastname"
                        :label="$t('applicationLastname')"
                        ref="lastname"
                    />
                </div>

                <div class="field-header">{{ $t('applicationApplicantEmail') }}*</div>
                <div class="fieldset">
                    <InputField v-model="applicant.email" 
                        :label="$t('applicationEmail')"
                        ref="email"
                    />
                    <InputField v-model="applicant.emailRepeat"
                        :label="$t('applicationEmail')"
                        :placeholder="$t('applicationApplicantEmailRepeat')"
                        ref="emailRepeat"
                    />
                </div>

                <div class="field-header">{{ $t('applicationTeamMembers') }}</div>
                <div v-if="teamMembers" class="fieldset" v-for="member in teamMembers" :key="member.id">
                    <InputField v-model="member.data.prefix"
                        :label="$t('applicationPrefix')"
                        :ref="'prefix' + '_' + member.id"
                    />
                    <InputField v-model="member.data.title"
                        :label="$t('applicationTitle')"
                        :ref="'title' + '_' + member.id"
                    />
                    <InputField v-model="member.data.firstname"
                        :label="$t('applicationFirstname')"
                        :ref="'firstname' + '_' + member.id"
                    />
                    <InputField v-model="member.data.lastname"
                        :label="$t('applicationLastname')"
                        :ref="'lastname' + '_' + member.id"
                    />
                    <InputField v-model="member.data.email"
                        :label="$t('applicationEmail')"
                        :ref="'email' + '_' + member.id"
                    />
                    <div class="remove-fieldset" v-on:click="removeTeamMember(member)">
                        {{ $t('applicationTeamMembersRemove') }}
                    </div>
                </div>
                <div v-if="teamMembers.length == 0" v-on:click="addTeamMember" class="add-fieldset">
                    {{ $t('applicationTeamMembersAddFirst') }}
                </div>
                <div v-else v-on:click="addTeamMember" class="add-fieldset">
                    {{ $t('applicationTeamMembersAdd') }}
                </div>

                <div class="field-header">{{ $t('applicationAllowContact') }}*</div>
                <RadioField v-model="allowContact"
                    :options="getOptions('allowContact', 'applicationAllowContact')"
                    name="allowContact"
                    :preselect="0"
                />

                <div class="field-header">{{ $t('applicationFacility') }}*</div>
                <div class="fieldset">
                    <SelectField v-model="facility"
                        :options="getOptions('facility', 'applicationFacility')"
                        :placeholder="$t('applicationSelect')"
                        ref="facility"
                    />
                    <InputField v-model="facilityOther"
                        :label="$t('applicationOther')"
                        ref="facilityOther"
                    />
                </div>

                <div class="field-header">{{ $t('applicationGroup') }}*</div>
                <div class="fieldset">
                    <SelectField v-model="group"
                        :options="getOptions('group', 'applicationGroup')"
                        :placeholder="$t('applicationSelect')"
                        ref="group"
                    />
                    <InputField v-model="groupOther"
                        :label="$t('applicationOther')"
                        ref="groupOther"
                    />
                </div>

                <div class="field-header">{{ $t('applicationSubjectOfStudy') }}*</div>
                <div class="fieldset single">
                    <InputField v-model="subjectOfStudy"
                        ref="subjectOfStudy"
                    />
                </div>

                <div class="field-header">{{ $t('applicationConnection') }}</div>
                <div class="fieldset single">
                    <InputField v-model="connection"
                        :placeholder="$t('applicationConnectionInfo')"
                        ref="connection"
                    />
                </div>

                <div class="field-header">{{ $t('applicationAttention') }}*</div>
                <div class="fieldset">
                    <SelectField v-model="attention"
                        :options="getOptions('attention', 'applicationAttention')"
                        :placeholder="$t('applicationSelect')"
                        ref="attention"
                    />
                    <InputField v-model="attentionOther"
                        :label="$t('applicationOther')"
                        ref="attentionOther"
                    />
                </div>

                <br>
                <br>
                <h4 class="section-header">{{ $t('applicationIdea') }}</h4>

                <div class="field-header">{{ $t('applicationIdeaName') }}*</div>
                <div class="fieldset single">
                    <InputField v-model="ideaName"
                        ref="ideaName"
                    />
                </div>

                <div class="field-header">{{ $t('applicationIdeaDescription') }}*</div>
                <div class="fieldset single">
                    <TextareaField v-model="ideaDescription"
                        ref="ideaDescription"
                    />
                </div>

                <div class="field-header">{{ $t('applicationIdeaProblem') }}*</div>
                <div class="fieldset single">
                    <TextareaField v-model="ideaProblem"
                        ref="ideaProblem"
                    />
                </div>

                <div class="field-header">{{ $t('applicationIdeaState') }}</div>
                <div class="fieldset single">
                    <TextareaField v-model="ideaState"
                        ref="ideaState"
                    />
                </div>

                <div class="field-header">{{ $t('applicationSupport') }}*</div>
                <div class="fieldset">
                    <SelectField v-model="support"
                        :options="getOptions('support', 'applicationSupport')"
                        :placeholder="$t('applicationSelect')"
                        ref="support"
                    />
                    <InputField v-model="supportOther"
                        :label="$t('applicationOther')"
                        ref="supportOther"
                    />
                </div>
                
                <br>
                <Separator class="no-padding" :cmsBlock="{background: 'white', separatorStyle: 'full_width'}"/>
                <br>

                <div class="fieldset finish">
                    <div class="field privacy-policy">
                        <div class="checkbox-wrapper" ref="privacyPolicyField">
                            <label>
                                <input ref="privacyPolicy" type="checkbox" v-model="privacyPolicyConsent"/>
                                <span class="checkmark"></span>
                                <span v-html="$t('applicationPrivacyPolicyConsent')"></span>
                            </label>
                        </div>
                    </div>

                    <div class="centered">* {{ $t('applicationRequiredFields') }}</div>

                    <button class="send button-dark" v-on:click="sendApplication">
                        {{ $t('applicationSend') }}
                    </button>
                </div>
            </div>

        </section>

        <PageBuilder v-if="!applicationSent && !applicationFailed" :cmsBlocks="cmsBlocks"/>
    </div>
</template>

<script>
    import PageBuilder from '@/components/PageBuilder'
    import TextBlock from '@/components/PageBuilder/TextBlock'
    import Separator from '@/components/PageBuilder/Separator'
    import { applicationMutation } from '@/mutations'
    import { scrollToElement } from '@/helper'

    import InputField from '@/components/Form/InputField'
    import TextareaField from '@/components/Form/TextareaField'
    import RadioField from '@/components/Form/RadioField'
    import SelectField from '@/components/Form/SelectField'
    import CheckboxField from '@/components/Form/CheckboxField'

    export default {
        name: 'ApplicationForm',

        components: {
            PageBuilder,
            TextBlock,
            Separator,
            InputField,
            TextareaField,
            RadioField,
            SelectField,
            CheckboxField
        },

        props: [
            'applicationText',
            'applicationSuccess',
            'applicationError',
            'enums',
            'cmsBlocks'
        ],

        data() {
            return {
                applicationSent: false,
                applicationFailed: false,

                applicant: {
                    prefix: null,
                    title: null,
                    firstname: null,
                    lastname: null,
                    email: null,
                    emailRepeat: null
                },

                teamMembers: [{
                    id: 0,
                    data: {
                        prefix: null,
                        title: null,
                        firstname: null,
                        lastname: null,
                        email: null,
                    }
                }],

                allowContact: null,
                facility: null,
                group: null,
                attention: null,
                support: null,

                facilityOther: null,
                groupOther: null,
                attentionOther: null,
                supportOther: null,

                subjectOfStudy: null,
                connection: null,
                ideaName: null,
                ideaDescription: null,
                ideaProblem: null,
                ideaState: null,
                privacyPolicyConsent: false
            }
        },

        mounted() {
            this.allowContact = this.enums.allowContact[0]
        },

        computed: {
            cmsBlockIntro() {
                return {
                    text: this.applicationText,
                    wysiwygStyle: 'white'
                }
            },

            cmsBlockSuccess() {
                return {
                    text: this.applicationSuccess,
                    wysiwygStyle: 'white'
                }
            },

            cmsBlockError() {
                return {
                    text: this.applicationError,
                    wysiwygStyle: 'white'
                }
            },

            mutationVariables() {
                var members = []
                if(this.teamMembers){
                    this.teamMembers.forEach(function(member){
                        members.push({
                            prefix: member.data.prefix,
                            title: member.data.title,
                            firstname: member.data.firstname,
                            lastname: member.data.lastname,
                            email: member.data.email
                        })
                    })
                }
                return {
                    applicant: {
                        prefix: this.applicant.prefix,
                        title: this.applicant.title,
                        firstname: this.applicant.firstname,
                        lastname: this.applicant.lastname,
                        email: this.applicant.email
                    },
                    teamMembers: members,

                    allowContact: this.allowContact,
                    facility: this.facility,
                    group: this.group,
                    attention: this.attention,
                    support: this.support,

                    facilityOther: this.facilityOther,
                    groupOther: this.groupOther,
                    attentionOther: this.attentionOther,
                    supportOther: this.supportOther,

                    subjectOfStudy: this.subjectOfStudy,
                    connection: this.connection,
                    ideaName: this.ideaName,
                    ideaDescription: this.ideaDescription,
                    ideaProblem: this.ideaProblem,
                    ideaState: this.ideaState,
                    privacyPolicyConsent: this.privacyPolicyConsent,
                }
            }
        },

        methods: {
            addTeamMember() {
                this.teamMembers.push({
                    id: this.teamMembers.length,
                    data: {
                        prefix: null,
                        title: null,
                        firstname: null,
                        lastname: null,
                        email: null
                    }
                })
            },

            removeTeamMember(member) {
                this.teamMembers.splice(this.teamMembers.indexOf(member), 1)
            },

            getOptions(key, i18n) {
                var self = this
                var options = []
                if(this.enums && this.enums[key]){
                    this.enums[key].forEach(function(option, i){
                        options.push({
                            value: option,
                            label: self.$t(`${i18n}_${option}`)
                        })
                    })
                }
                return options
            },

            validateSingle(element) {
                if(element.value && element.value.length > 0){
                    element.$el.classList.remove('invalid')
                    return true
                } else {
                    element.$el.classList.add('invalid')
                    return false
                }
            },

            validateMail() {
                var emailEmpty = true
                var validFormat = false
                var emailSame = false

                if(this.$refs.email.value && this.$refs.email.value.length > 0){
                    emailEmpty = false
                }
                if(this.$refs.email.value && this.validateEmailRegex(this.$refs.email.value)){
                    validFormat = true
                }
                if(this.$refs.email.value && this.$refs.emailRepeat.value && this.$refs.email.value == this.$refs.emailRepeat.value){
                    emailSame = true
                }

                if(emailEmpty || !validFormat){
                    this.$refs.email.$el.classList.add('invalid')
                    this.$refs.emailRepeat.$el.classList.add('invalid')
                }
                if(validFormat && !emailSame){
                    this.$refs.email.$el.classList.remove('invalid')
                    this.$refs.emailRepeat.$el.classList.add('invalid')
                }
                if(validFormat && emailSame){
                    this.$refs.email.$el.classList.remove('invalid')
                    this.$refs.emailRepeat.$el.classList.remove('invalid')
                }
                if(!emailEmpty && validFormat && emailSame){
                    return true
                } else {
                    return false
                }
            },

            validateSingleMail(element) {
                if(element.value && element.value.length > 0 && this.validateEmailRegex(element.value)){
                    element.$el.classList.remove('invalid')
                    return true
                } else {
                    element.$el.classList.add('invalid')
                    return false
                }
            },

            validateEmailRegex(email) {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return re.test(String(email).toLowerCase())
            },

            validateComboField(element1, element2){
                if(element1.value && element1.value.length > 0){
                    element1.$el.classList.remove('invalid')
                    return true
                } else if(element2.value && element2.value.length > 0){
                    element1.$el.classList.remove('invalid')
                    return true
                } else {
                    element1.$el.classList.add('invalid')
                    return false
                }
            },

            validateAll() {
                var self = this

                var scrolled = false

                var singleFields = [
                    this.$refs.prefix,
                    this.$refs.firstname,
                    this.$refs.lastname,
                    this.$refs.subjectOfStudy,
                    this.$refs.ideaName,
                    this.$refs.ideaDescription,
                    this.$refs.ideaProblem
                ]
                var singleFieldsValid = true

                singleFields.forEach(function(field){
                    singleFieldsValid &= self.validateSingle(field)
                    if(!singleFieldsValid && !scrolled){
                        scrollToElement(field.$el)
                        scrolled = true
                    }
                })

                var teamMembersFields = [
                    'prefix',
                    'firstname',
                    'lastname'
                ]
                var teamMembersFieldsValid = true

                this.teamMembers.forEach(function(member){
                    var memberFieldsValid = true
                    teamMembersFields.forEach(function(field){
                        var ref = field + '_' + member.id
                        var field = self.$refs[ref][0]
                        memberFieldsValid &= self.validateSingle(field)
                        if(!memberFieldsValid && !scrolled){
                            scrollToElement(field.$el)
                            scrolled = true
                        }
                        teamMembersFieldsValid &= memberFieldsValid
                    })
                    var ref = 'email_' + member.id
                    var field = self.$refs[ref][0]
                    memberFieldsValid &= self.validateSingleMail(field)
                    if(!memberFieldsValid && !scrolled){
                        scrollToElement(field.$el)
                        scrolled = true
                    }
                    teamMembersFieldsValid &= memberFieldsValid
                })

                var emailValid = this.validateMail()
                if(!emailValid && !scrolled){
                    scrollToElement(this.$refs.email.$el)
                    scrolled = true
                }

                var comboFields = [
                    [this.$refs.facility, this.$refs.facilityOther],
                    [this.$refs.group, this.$refs.groupOther],
                    [this.$refs.attention, this.$refs.attentionOther],
                    [this.$refs.support, this.$refs.supportOther]
                ]
                var comboFieldsValid = true

                comboFields.forEach(function(fields){
                    comboFieldsValid &= self.validateComboField(fields[0], fields[1])
                    if(!comboFieldsValid && !scrolled){
                        scrollToElement(fields[0].$el)
                        scrolled = true
                    }
                })

                if(this.$refs.privacyPolicy.checked){
                    this.$refs.privacyPolicyField.classList.remove('invalid')
                } else {
                    this.$refs.privacyPolicyField.classList.add('invalid')
                }

                return (singleFieldsValid &&
                        emailValid &&
                        comboFieldsValid &&
                        this.$refs.privacyPolicy.checked)
            },

            sendApplication() {
                if(this.validateAll()){
                    var self = this
                    this.$apollo.mutate({
                        mutation: applicationMutation,
                        variables: self.mutationVariables
                    }).then(function(data){
                        self.applicationSent = true
                    }).catch(function(err){
                        self.applicationFailed = true
                        console.log(err)
                    })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .white.no-padding {
        padding: 0;
    }

    .form {
        display: flex;
        flex-direction: column;
        max-width: 1560px;
        margin: 0 auto;
        padding-bottom: 1px;
        margin-bottom: 100px;
        font-size: getFontSize('smallText', 'desktop');

        @include breakpoint('max-width') {
            margin: 0 getSpacing('section-left-right', 'desktop');
        }
        @include breakpoint('tablet') {
            margin: 0 getSpacing('section-left-right', 'tablet');
            font-size: getFontSize('smallText', 'tablet');
        }
        @include breakpoint('mobile') {
            margin: 0 getSpacing('section-left-right', 'mobile');
            font-size: getFontSize('smallText', 'mobile');
        }

        .field-header {
            font-weight: bold;
            text-transform: uppercase;
            margin: 30px 0;
        }

        .section-header {
            font-size: getFontSize('defaultText', 'desktop');
            font-family: getFontFamily('defaultText');
            font-weight: getFontWeight('defaultText');
            text-transform: uppercase;
            text-align: center;
            border-bottom: 2px solid $timberGreen;
            padding-bottom: 12px;
            margin-bottom: 40px;

            @include breakpoint('tablet') {
                font-size: getFontSize('defaultText', 'tablet');
            }
            @include breakpoint('mobile') {
                font-size: getFontSize('defaultText', 'mobile');
            }
        }

        .add-fieldset, .remove-fieldset {
            text-decoration: underline;
            cursor: pointer;
            line-height: 40px;
        }
        .add-fieldset {
            max-width: 360px;
            margin-top: -10px;
            margin-bottom: 20px;
        }

        .send {
            max-width: 400px;
            align-self: center;
            margin-top: 50px;
        }

        .field.privacy-policy {
            border: none;
            justify-content: center;
            height: unset;

            .checkbox-wrapper {
                position: relative;

                label {
                    cursor: pointer;
                    position: relative;
                }

                input {
                    opacity: 0;
                    margin-right: 20px;

                    &:checked {
                        ~ .checkmark {
                            &:after {
                                opacity: 1;
                            }
                        }
                    }
                }

                .checkmark {
                    position: absolute;
                    left: 0;
                    top: -4px;
                    height: 20px;
                    width: 20px;
                    border: 1px solid $timberGreen;

                    &:after {
                        content: "";
                        position: absolute;
                        width: 10px;
                        height: 10px;
                        bottom: 5px;
                        left: 5px;
                        background: $timberGreen;
                        opacity: 0;
                    }
                }

                &.invalid {
                    border-bottom: 2px solid red;
                }
            }
        }
    }
</style>

<style lang="scss">
    .form {
        .separator.full_width {
            @include breakpoint('tablet') {
                width: 100%;
                margin: 0;
            }
        }
    }

    .fieldset {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 30px;

        &.single {
            .field {
                width: 100%;
            }
        }

        &.finish {
            display: flex;
            flex-direction: column;

            .field {
                width: 100%;
            }
        }

        .centered.field, .centered {
            border: none;
            text-align: center;
            display: block;
            width: 100%;
        }
    }

    .field {
        display: flex;
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid black;
        width: calc(50% - 25px);
        margin-bottom: 10px;

        @include breakpoint('mobile') {
            width: 100%;
        }

        &.invalid {
            border-color: red;
            border-width: 2px;
            margin-bottom: 9px;

            textarea {
                border-width: 2px;
                border-color: red;

                &:focus-visible {
                    border-color: red;
                    border-width: 2px;
                }
            }

            .select {
                border-color: red;
                border-width: 2px;
            }
        }

        a {
            text-decoration: underline;

            @include breakpoint('desktop') {
                font-size: getFontSize('smallText', 'desktop')
            }
            @include breakpoint('mobile') {
                font-size: getFontSize('smallText', 'mobile')
            }
        }
    }
</style>
