<template>
    <div class="field">
        <label v-if="label">{{ label }}</label>
        <input @input="updateValue($event.target.value)" :placeholder="placeholder">
    </div>
</template>

<script>
    export default {
        name: 'InputField',

        props: [
            'value',
            'label',
            'placeholder'
        ],

        methods: {
            updateValue(value) {
                this.$emit('input', value.length > 0 ? value : null)
            }
        }
    }
</script>

<style lang="scss" scoped>
    input {
        flex-grow: 1;
        border: none;
        padding: 0;
        line-height: 40px;
        font-size: getFontSize('smallText', 'desktop');
        font-family: getFontFamily('smallText');
        font-weight: getFontWeight('smallText');

        @include breakpoint('tablet') {
            font-size: getFontSize('smallText', 'tablet');
        }
        @include breakpoint('mobile') {
            font-size: getFontSize('smallText', 'mobile');
        }

        &::placeholder {
            font-style: italic;
            color: $timberGreen;
            opacity: 0.5;
        }

        &:focus-visible {
            border: none;
            box-shadow: none;
            outline: none;
        }
    }

    label {
        margin-right: 10px;

        &:after {
            content: ':';
        }
    }
</style>
