<template>
    <div v-if="dataLoaded">
        <div class="entry-short" v-bind:style="{ backgroundImage: 'url(' + cmsPageHeaderImage + ')' }">
            <div class="context">
                <h1>{{ localize(application.data.attributes.title) }}</h1>
                <h2>{{ localize(application.data.attributes.subtitle) }}</h2>
            </div>
        </div>

        <ApplicationForm
            :applicationText="application.data.attributes.applicationText"
            :applicationSuccess="application.data.attributes.applicationSuccess"
            :applicationError="application.data.attributes.applicationError"
            :enums="enums"
            :cmsBlocks="application.data.attributes.cmsBlocks"
        />
    </div>
</template>

<script>
    import ApplicationForm from '@/components/ApplicationForm'
    import { applicationQuery, globalOptionQuery, enumQuery } from '@/queries'

    export default {
        name: 'Application',

        components: {
            ApplicationForm
        },

        data() {
            return {
                application: null,
                loading: true
            }
        },

        apollo: {
            application: {
                query: applicationQuery,
                watchLoading(isLoading, countModifier) {
                    this.loading = isLoading
                }
            },

            defaultCmsPageHeader: {
                query: globalOptionQuery,
                variables: {
                    optionId: 'default-cms-page-header'
                },
                fetchPolicy: 'cache-first',
                update: data => data.globalOptions.data[0]
            },

            allowContactEnum: {
                query: enumQuery,
                variables: {
                    enumName: 'ENUM_RECEIVEDAPPLICATION_ALLOWCONTACT'
                },
                update: data => data.__type.enumValues.map(function(item){return item.name})
            },
            facilityEnum: {
                query: enumQuery,
                variables: {
                    enumName: 'ENUM_RECEIVEDAPPLICATION_FACILITY'
                },
                update: data => data.__type.enumValues.map(function(item){return item.name})
            },
            groupEnum: {
                query: enumQuery,
                variables: {
                    enumName: 'ENUM_RECEIVEDAPPLICATION_GROUP'
                },
                update: data => data.__type.enumValues.map(function(item){return item.name})
            },
            attentionEnum: {
                query: enumQuery,
                variables: {
                    enumName: 'ENUM_RECEIVEDAPPLICATION_ATTENTION'
                },
                update: data => data.__type.enumValues.map(function(item){return item.name})
            },
            supportEnum: {
                query: enumQuery,
                variables: {
                    enumName: 'ENUM_RECEIVEDAPPLICATION_SUPPORT'
                },
                update: data => data.__type.enumValues.map(function(item){return item.name})
            }
        },

        computed: {
            dataLoaded() {
                return this.application != null
            },

            cmsPageHeaderImage() {
                return this.defaultCmsPageHeader ? this.getImageUrl(this.localize(this.defaultCmsPageHeader.attributes.image).data.attributes.url) : ''
            },

            enums() {
                return {
                    allowContact: this.allowContactEnum,
                    facility: this.facilityEnum,
                    group: this.groupEnum,
                    attention: this.attentionEnum,
                    support: this.supportEnum
                }
            }
        }
    }
</script>
