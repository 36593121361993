import gql from 'graphql-tag'

// Compatible to v4
export const applicationMutation = gql`mutation application(
    $applicant: ComponentApplicationPersonInput!
    $teamMembers: [ComponentApplicationPersonInput]
    $allowContact: ENUM_RECEIVEDAPPLICATION_ALLOWCONTACT!
    $facility: ENUM_RECEIVEDAPPLICATION_FACILITY
    $group: ENUM_RECEIVEDAPPLICATION_GROUP
    $attention: ENUM_RECEIVEDAPPLICATION_ATTENTION
    $support: ENUM_RECEIVEDAPPLICATION_SUPPORT
    $facilityOther: String
    $groupOther: String
    $attentionOther: String
    $supportOther: String
    $subjectOfStudy: String!
    $connection: String
    $ideaName: String!
    $ideaDescription: String!
    $ideaProblem: String!
    $ideaState: String
    $privacyPolicyConsent: Boolean!
  ) {
    createReceivedApplication(
      data: {
        applicant: $applicant
        teamMembers: $teamMembers
        allowContact: $allowContact
        facility: $facility
        group: $group
        attention: $attention
        support: $support
        facilityOther: $facilityOther
        groupOther: $groupOther
        attentionOther: $attentionOther
        supportOther: $supportOther
        subjectOfStudy: $subjectOfStudy
        connection: $connection
        ideaName: $ideaName
        ideaDescription: $ideaDescription
        ideaProblem: $ideaProblem
        ideaState: $ideaState
        privacyPolicyConsent: $privacyPolicyConsent
      }
    ) {
      data {
        attributes {
          applicant {
            prefix
            title
            firstname
            lastname
            email
          }
          teamMembers {
            prefix
            title
            firstname
            lastname
            email
          }
          allowContact
          facility
          group
          attention
          support
  
          facilityOther
          groupOther
          attentionOther
          supportOther
  
          subjectOfStudy
          connection
          ideaName
          ideaDescription
          ideaProblem
          ideaState
          privacyPolicyConsent
        }
      }
    }
  }
  `

  // Compatible to v4
export const reachMemberMutation = gql`mutation application(
  $prefix: ENUM_REACHMEMBER_PREFIX,
  $title: ENUM_REACHMEMBER_TITLE,
  $firstname: String!,
  $lastname: String!,
  $email: String!,
  $statusgroup: ENUM_REACHMEMBER_STATUSGROUP,
  $attention: ENUM_REACHMEMBER_ATTENTION,
  $interests: JSON,
  $tracks: JSON,
  $message: String,
) {
  createReachMember(
    data: {
      prefix: $prefix,
      title: $title,
      firstname: $firstname,
      lastname: $lastname,
      email: $email,
      statusGroup: $statusgroup,
      attention: $attention,
      interests: $interests,
      tracks: $tracks,
      message: $message,
    }
  ) {
    data {
      attributes {
        prefix
        title
        firstname
        lastname
        email
        statusGroup
        attention
        interests
        tracks
        message
      }
    }
  }
}
`
